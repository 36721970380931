import styled from "styled-components/macro";

export const StyledGlobalErrorMessage = styled.div`
  position: fixed;
  z-index: 200;
  width: 500px;
  @media (max-width: 540px) {
    width: 320px;
  }
  background: ${(props) =>
    props.notError ? props.theme.colors.green : props.theme.colors.highlight};
  color: #fff;
  padding: 20px;
  left: 50%;
  bottom: 50px;
  border-radius: 15px;
  transform: translate(-50%, 0);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  animation: animateIn 0.25s ease-in-out;
  display: flex;
  align-items: center;
  @keyframes animateIn {
    0% {
      opacity: 0;
      bottom: 0px;
    }
    100% {
      opacity: 1;
      bottom: 50px;
    }
  }
  ${(props) =>
    props.fadeOut
      ? `
    animation: fadeOut 2s;
    @keyframes fadeOut {
        0% { 
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    
    `
      : ``}
`;

export const StyledDismissButton = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  width: 16px;
  height: 16px;
  border-radius: 12px;
  transition: all 0.15s ease-in-out;
  :after,
  :before {
    content: "";
    width: 10px;
    height: 1px;
    position: absolute;
    top: 7px;
    left: 3px;
    transform: rotate(45deg);
    background: ${(props) => props.theme.colors.lgrey};
  }
  :before {
    transform: rotate(-45deg);
  }
  :hover {
    background: rgba(200, 200, 200, 0.5);
  }
`;
