//single position

// React
import React, { useCallback, useContext, useEffect, useState } from "react";

// Components
import { Grid, Row, Col } from "../../../components/Grid";
import { Icon, TogglePosition, Modal } from "../../../components";
import { MegaHeader } from "../../components";
import { Typography } from "../../../components/Typography";
import {
  getGenericLocalStorage,
  setGenericLocalStorage,
} from "../../../utils/localSave";
import EditPositionView from "./EditPositionView/EditPositionView";
import { ApplicationContextWrapper } from "../../components/ApplicationList";
import Kanban from "./Kanban/Kanban";
import InviteCandidate from "../Modals/InviteCandidate/InviteCandidate";
import PromoteHiBoost from "../Modals/PromoteHiBoost/PromoteHiBoost";

// Routing and views
import { useHistory, useParams } from "react-router-dom";
import AllPositions from "../AllPositions/AllPositions";
import Application from "../Application/Application";

// API Endpoints - Custom hooks - utility functions
import { getPositions } from "../../../api/endpoints/position";
import { useQuerySearchParams } from "../../hooks/useQuerySearchParams";
import { formatDate } from "../../../utils/various";

// State management
import {
  DispatchPositionContext,
  StatePositionContext,
} from "../../context/PositionContext";
import { StateOrganizationContext } from "../../context/OrganizationContext";
import { DispatchErrorContext } from "../../../context/ErrorContext";
import { DispatchMessageContext } from "../../../context/MessageContext";

// Translations
import { useTranslation } from "react-i18next";

// Styled components and React query
import { useQueryClient } from "react-query";
import styled from "styled-components/macro";
import {
  StyledTopBar,
  TopBarBottom,
  TopBarTop,
  TopBarBottomContent,
} from "../../components/TopBar/style";
import { EditButton, PrimaryButton } from "../../../components/Button";
import { Flex } from "../../style";
import { MobileOnly, DesktopOnly } from "../../style";

// Styled --
export const SinglePositionContainer = styled.div`
  width: calc(100% - 400px);
  width: ${(props) =>
    props.collapse ? "100% !important" : "calc(100% - 400px)"};
  transition: 0.5s ease;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
  @media (max-width: 992px) {
    max-width: 100vw;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    /* overflow-y: scroll; */
    position: absolute;
    left: ${(props) => (props.viewCount ? "0" : "100%")};
    top: 0;
    transition: 0.5s ease;
  }
  @media (min-width: 1480px) {
    width: calc(100% - 480px);
  }
`;

export const Position = () => {
  const { t, i18n } = useTranslation();
  const { organization } = useContext(StateOrganizationContext);
  const {
    single,
    isLoading,
    unsaved,
    anonUpdated,
    count,
    collapsed,
    editPosition,
  } = useContext(StatePositionContext);
  const positionState = useContext(StatePositionContext);

  const positionDispatch = useContext(DispatchPositionContext);
  const errorDispatch = useContext(DispatchErrorContext);
  const messageDispatch = useContext(DispatchMessageContext);
  let query = useQuerySearchParams();
  let { id } = useParams();
  let history = useHistory();
  const queryClient = useQueryClient();
  const [positionId, setPositionId] = useState(null);
  const [inviteCandidateModal, setInviteCandidateModal] = useState(false);
  const [promoteHiBoost, setPromoteHiBoost] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [animated, setAnimated] = useState(false);

  const [fetchData, setFetchData] = useState([]);

  const handleFetchData = useCallback(
    (newData) => {
      setFetchData(newData);
    },
    [setFetchData]
  );

  useEffect(() => {
    // ... effect logic
  }, [positionId]);

  const pageId = single?.page?.id;

  useEffect(() => {
    positionDispatch({ type: "edit_position_view", payload: false });
  }, []);

  //set this as key back to
  useEffect(() => {
    //save to key back location
    let currentAdminSettings = {};
    if (getGenericLocalStorage("admin_settings")) {
      currentAdminSettings = getGenericLocalStorage("admin_settings", true);
    }
    setGenericLocalStorage(
      "admin_settings",
      {
        ...currentAdminSettings,
        keyBackLocation: `/position/${positionId}`,
      },
      true
    );
  }, [positionId]);

  useEffect(() => {
    setPositionId(id);
  }, [id]);

  useEffect(() => {
    const creatingNewPosition = id === "create";
    if (creatingNewPosition) {
      positionDispatch({
        type: "init_new_position",
        payload: {
          t,
          initialLanguage: i18n.language,
        },
      });
      positionDispatch({ type: "edit_position_view", payload: true });
    }

    const fetchPosition = async () => {
      positionDispatch({ type: "init_single_position" });
      let request = {
        selector: id,
        params: { includeSignedRequest: 1 },
      };
      if (id === "unsolicited") {
        request = {
          params: { unsolicited: 1, includeSignedRequest: 1 },
        };
      }
      try {
        const response = await getPositions(request);
        if (response.success && response.data !== null) {
          positionDispatch({
            type: "loaded_single_position",
            payload: { data: response.data, t: t },
          });
          setPositionId(response.data.id);
          setTimeout(function () {
            setIsLoading(false);
          }, 500);
        } else {
          errorDispatch({
            type: "set_error",
            payload: t("error.could_not_get_position"),
          });
          history.push("/404");
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (!creatingNewPosition && positionId) {
      fetchPosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, positionId, positionDispatch, errorDispatch, history]);

  const handleInviteCandidate = () => {
    setInviteCandidateModal((prevState) => !prevState);
  };

  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const handleHiBoost = () => {
    setPromoteHiBoost((prevState) => !prevState);
  };

  const handlePromoteConfirmation = (value) => {
    setPromoteHiBoost(value);
  };

  useEffect(() => {
    // Delay the animation start by a small amount (e.g., 100ms)
    const animationTimeout = setTimeout(() => {
      setAnimated(true);
    }, 500);

    // Clear the timeout when the component unmounts or when the animation starts
    return () => clearTimeout(animationTimeout);
  }, []);

   /**
   * State variable to track component mounted state
   */
   const [isMounted, setIsMounted] = useState(false);
   useEffect(() => {
     setIsMounted(true); // Component is mounted
     return () => {
       setIsMounted(false); // Component is unmounted
     };
   }, []);

  return (
    <>
      {/* <Loading loading={loading} /> */}
      <AllPositions isHidden />
      <Application allApplications={fetchData?.data?.pages[0]} />
      <SinglePositionContainer viewCount={count} collapse={collapsed}>
        {!editPosition && (
          <StyledTopBar>
            <TopBarTop>
              {single && !isLoading ? (
                <MegaHeader
                  text={
                    single.title === "Unsolicited applications"
                      ? t("common.application.unsolicited_applications")
                      : single.title
                  }
                />
              ) : (
                <MegaHeader />
              )}
            </TopBarTop>
            <TopBarBottom>
              <TopBarBottomContent>
                <MobileOnly>
                  <div>
                    <EditButton
                      onClick={
                        !editPosition
                          ? () => {
                              positionDispatch({
                                type: "edit_position_view",
                                payload: true,
                              });
                            }
                          : null
                      }
                    >
                      {t("common.editPosition")}
                      <Icon icon="edit" mr="5" />
                    </EditButton>
                  </div>
                </MobileOnly>
                <DesktopOnly>
                  <Typography tag="p" nomargin color="#181F38" large lowOpacity>
                    {t("common.createdOn")}{" "}
                    {formatDate(single?.page?.createdAt)}
                  </Typography>
                </DesktopOnly>
                <Flex widthSM="fit-content;">
                  <TogglePosition />
                  {/* <PositionPublish /> */}
                </Flex>
              </TopBarBottomContent>
            </TopBarBottom>
          </StyledTopBar>
        )}
        {/* SINGLE POSITION */}
        <ApplicationContextWrapper positionId={single.id}>
          <Grid
            expand
            fluid
            column
            initialOpacity={!animated}
            animated={animated}
          >
            {!editPosition ? (
              <>
                <Row backgroundColor="#F3F3F5" height="100%">
                  <DesktopOnly fullWidth>
                    <Row width="100%;" noMargin paddingSM="15px 15px 15px 15px">
                      <Col vCenter>
                        <PrimaryButton
                          white
                          onClick={
                            !editPosition
                              ? () => {
                                  positionDispatch({
                                    type: "edit_position_view",
                                    payload: true,
                                  });
                                }
                              : null
                          }
                        >
                          {t("common.editPosition")}
                          <Icon icon="edit" mr="10" />
                        </PrimaryButton>
                      </Col>
                      <Col right vCenter>
                        {showConfirmationModal && (
                          <Modal
                            title={t("common.inviteCandidateConfirmationTitle")}
                            onClose={() => setShowConfirmationModal(false)}
                          >
                            <Grid padding="32" alignCenter>
                              <Typography tag="p" nomargin>
                                {t("common.inviteCandidateConfirmationDesc")}
                              </Typography>
                              <PrimaryButton
                                transparent
                                mt="18"
                                onClick={() => setShowConfirmationModal(false)}
                              >
                                {t("common.great")}
                                <Icon
                                  icon="thumbs_up"
                                  mr="8"
                                  lowOpacity
                                  invert
                                />
                              </PrimaryButton>
                            </Grid>
                          </Modal>
                        )}
                        {inviteCandidateModal ? (
                          <Modal
                            title={t(
                              "page.invite_candidate.invite_candidate",
                              "Invite candidate"
                            )}
                            onClose={handleInviteCandidate}
                          >
                            <InviteCandidate
                              onClose={handleInviteCandidate}
                              openConfirmationModal={openConfirmationModal}
                            />
                          </Modal>
                        ) : null}
                        <PrimaryButton
                          mr="20"
                          white
                          onClick={handleInviteCandidate}
                        >
                          {t("common.inviteCandidate")}
                          <Icon icon="add_person" mr="10" />
                        </PrimaryButton>
                        {promoteHiBoost ? (
                          <Modal
                            title={t(
                              "common.promotewithHiBoost",
                              "Promote with HiBoost"
                            )}
                            onClose={handleHiBoost}
                          >
                            <PromoteHiBoost
                              single={single}
                              onClose={handleHiBoost}
                              onPromoteConfirmation={handlePromoteConfirmation}
                            />
                          </Modal>
                        ) : null}
                        <PrimaryButton red onClick={handleHiBoost}>
                          {t("common.promotewithHiBoost")}
                          <Icon icon="rocket" mr="10" />
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </DesktopOnly>
                  <Col size={24}>
                    <Kanban
                      positionId={single.id}
                      title={single.title}
                      onFetchData={handleFetchData}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <Grid scrollable editPosition>
                <EditPositionView />
              </Grid>
            )}
          </Grid>
        </ApplicationContextWrapper>
      </SinglePositionContainer>
    </>
  );
};

export default Position;
