import styled from "styled-components/macro";

export const KanbanCardContainer = styled.div`
height: 115px; 
width: 100%; 
background-color: ${props => props.theme.colors.almostWhite}; 
border: 1px solid ${props => props.theme.colors.mgrey}; 
box-shadow: 0 0 8px rgba(24, 31, 56, 0.06);
border-radius: 5px;
margin-bottom: 20px;
padding: 16px; 
display: flex; 
flex-direction: column;
position: relative;
justify-content: space-between;
opacity: 0.5; 
transition: .2s ease; 
${props => props.initial && `
opacity: 1; 
transition: .5s ease;`
}
${props => props.active && `
opacity: 1; 
transition: .5s ease; 
`}
@media (max-width: 992px) {
    height: 80px; 
    margin-bottom: 8px;
}
`

export const KanbanContent = styled.div`
margin-left: 12px; 
margin-top: -3px;
h3 {
    font-size: 14px; 
    text-transform: capitalize;
    color: ${props => props.theme.colors.faceliftBlue};
    font-weight: 500;
    margin-bottom: 4px; 
}
${props => props.isAnonymous && `
h3 {
    filter: blur(5px); 
}
`}
`

export const KanbanCardAvatar = styled.div`
height: 48px; 
width: 48px;
background: ${props => `url(${props.avatar}) no-repeat top center`};
background-size: cover;
border-radius: 50px;
border: 1px solid ${props => props.theme.colors.mgrey}; 
@media (min-width: 992px) {
    border-radius: 22px; 
}
`

export const KanbanContactInfo = styled.div`
display: flex;
justify-content: space-between;
width: 100%; 
${props => props.isAnonymous && `
    filter: blur(5px); 
`}
p {
    font-size: 14px; 
    color: ${props => props.theme.colors.faceliftBlue}; 
    opacity: 0.6; 
    font-weight: 400; 
}
`

export const KanbanLinkContainer = styled.div`
position: absolute;
height: 100%; 
width: 100%;
z-index: 9; 
cursor: pointer;
top: 0; 
left: 0; 
`

export const IsInvited = styled.div`
position: absolute; 
right: 0;
top: 0;
display: flex; 
flex-direction: column;
justify-content: center;
align-items: center;
background-color: ${props => props.theme.colors.mgrey};
padding: 4px 8px; 
border-radius: 20px; 
p {
    font-size: 10px; 
    font-weight: bold; 
    color: rgba(24, 31, 56, 0.6);
    letter-spacing: 1.8px; 
}
div {
    width: 25px;
    object-fit: contain;
    height: 20px; 
}
p {
    font-size: 10px; 
}
`